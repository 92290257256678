<template>
  <transition
    name="slide-fade"
    @after-leave="showCompanyPanel = false"
    class="z-[999]"
  >
    <CompaniesMainMenu v-if="showCompanyPanel" @close="onCloseCompanyPanel" />
  </transition>
  <div
    :class="{
      'h-[600px] overflow-hidden': ajustMainContainer === true,
    }"
    class="bg-gray-100 dark:bg-gray-900 text-gray-800 dark:text-gray-200 font-sans antialiased"
  >
    <header-template
      @onCompanySelectionChangeEvent="onCompanySelectionChanged"
      @onClickShowAllCompaniesEvent="showCompanyPanel = !showCompanyPanel"
      @onClickMenuButtonEvent="isVisibleSideBar = !isVisibleSideBar"
    />

    <!-- Sidebar -->
    <side-bar :isVisible="isVisibleSideBar" />

    <!-- MAIN CONTAINER -->
    <slot />

    <footer-template />
  </div>
</template>
<script setup>
import HeaderTemplate from "@/components/Layout/HeaderTemplate.vue";
import CompaniesMainMenu from "@/components/Layout/CompaniesMainMenu.vue";
import SideBar from "@/components/Layout/SideBar.vue";
import FooterTemplate from "@/components/Layout/FooterTemplate.vue";
import "@vuepic/vue-datepicker/dist/main.css";
import { ref, watch } from "vue";
import { Key } from "@/components/stores/KeyString";
const showCompanyPanel = ref(true);
const ajustMainContainer = ref(true);
const isVisibleSideBar = ref(false);

const onCompanySelectionChanged = (company) => {
  if (company != null) {
    localStorage.setItem(Key.COMPANYSELECTED, JSON.stringify(company));
  }
};

const onCloseCompanyPanel = () => {
  showCompanyPanel.value = !showCompanyPanel.value;
  ajustMainContainer.value = false;
};

watch(
  () => showCompanyPanel.value,
  (newValue, _) => {
    if (newValue) {
      setTimeout(() => (ajustMainContainer.value = true), 400);
    }
  }
);
</script>
<style scoped>
/* Estilos de transición para el sidebar */
.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: transform 0.4s ease, opacity 0.4s ease;
}

.slide-fade-enter-from {
  transform: translateX(-100%);
  opacity: 0;
}

.slide-fade-enter-to {
  transform: translateX(0);
  opacity: 1;
}

.slide-fade-leave-from {
  transform: translateX(0);
  opacity: 0;
}

.slide-fade-leave-to {
  transform: translateX(-100%);
  opacity: 0;
}

@media (max-width: 800px) {
  .container {
    max-height: 8000px;
  }
  .item {
    flex: 1 1 calc(50% - 20px);
    max-width: calc(50% - 20px);
  }
}

/* Responsividad: cambia a 1 columna en pantallas pequeñas */
@media (max-width: 500px) {
  .container {
    max-height: auto;
  }
  .item {
    flex: 1 1 100%;
    max-width: 100%;
  }
}
@media (max-width: 400px) {
  .container {
    max-height: auto;
  }
  .item {
    flex: 1 1 100%;
    max-width: 100%;
  }
}
</style>
