import axios from "axios";
import apiConfig from "../configuration/api-config";

axios.defaults.baseURL = `${apiConfig.apiBaseUrl}`;
axios.defaults.headers.post["Content-Type"] = "application/json";

const wsBaseUrl = `/Catalogs.svc/json/`;

var wsResponse = {
  result: false,
  response: null,
};

async function SAT_Proveedores_Lista(
  devicekey,
  typeDevice,
  admixProduct,
  fechaInicial,
  fechaFinal,
  idEmpresa,
  numItems,
  numItemsLoaded,
  idUser,
  id = 0,
  search = null
) {
  const url = `${wsBaseUrl}SAT_Proveedores_Lista/`;

  //#region Validaciones
  if (!devicekey) {
    return {
      result: false,
      response: {
        Error: {
          Code: "WSC00",
          Message: "No se ha especificado una llave de dispositivo.",
          Solution: "Verifique la conección a la API de Admix.",
          Type: 1,
        },
      },
    };
  }

  if (!typeDevice) {
    return {
      result: false,
      response: {
        Error: {
          Code: "WSC00",
          Message: "No se ha especificado el tipo de dispositivo.",
          Solution: "Agrega un tipo de dispositivo disponible.",
          Type: 1,
        },
      },
    };
  }

  if (
    !(
      (typeDevice >= 0 && typeDevice <= 10) ||
      typeDevice == 100 ||
      typeDevice == -100
    )
  ) {
    return {
      result: false,
      response: {
        Error: {
          Code: "WSC00",
          Message: "Ese tipo de dispositivo no esta permitido.",
          Solution: "Verifica la lista de dispositivos disponibles.",
          Type: 1,
        },
      },
    };
  }

  if (!admixProduct) {
    return {
      result: false,
      response: {
        Error: {
          Code: "WSC00",
          Message: "No se ha especificado el tipo de aplicacion.",
          Solution: "Agrega un tipo de producto de Admix disponible.",
          Type: 1,
        },
      },
    };
  }

  if (
    !(
      (admixProduct >= -1 && admixProduct <= 16) ||
      admixProduct == 100 ||
      admixProduct == -100
    )
  ) {
    return {
      result: false,
      response: {
        Error: {
          Code: "WSC00",
          Message: "Ese tipo de aplicacion no esta permitida.",
          Solution: "Verifica la lista de productos de Admix disponibles.",
          Type: 1,
        },
      },
    };
  }
  //#endregion

  // Formar el Request con los datos de la peticion
  let request = {
    Request: {
      DeviceKey: devicekey,
      AdmixProduct: admixProduct,
      TypeDevice: typeDevice,
      Id: id,
      IdEmpresa: idEmpresa,
      FechaInicial: fechaInicial,
      FechaFinal: fechaFinal,
      Search: search,
      NumItemsLoaded: numItemsLoaded,
      NumItems: numItems,
      IdUser: idUser,
    },
  };
  try {
    //Enviar Peticion
    var res = await axios.post(url, request);

    //Regresar Respuesta obtenida de la variable data
    return {
      result: res.data.SAT_Proveedores_ListaResult,
      response: res.data.Response,
    };
  } catch (error) {
    wsResponse.response = {
      Error: {
        Code: "WSC01",
        Message: error,
        Solution: "Verifique la conección a la API de Admix.",
        Type: 1,
      },
    };
    return wsResponse;
  }
}

async function SAT_Proveedores_Widget_ProveedoresEncontrados(
  devicekey,
  typeDevice,
  admixProduct,
  idEmpresa,
  idUser,
  numItems,
  numItemsLoaded,
  search
) {
  const url = `${wsBaseUrl}SAT_Proveedores_Widget_ProveedoresEncontrados/`;

  //#region Validaciones
  if (!devicekey) {
    return {
      result: false,
      response: {
        Error: {
          Code: "WSC00",
          Message: "No se ha especificado una llave de dispositivo.",
          Solution: "Verifique la conección a la API de Admix.",
          Type: 1,
        },
      },
    };
  }

  if (!typeDevice) {
    return {
      result: false,
      response: {
        Error: {
          Code: "WSC00",
          Message: "No se ha especificado el tipo de dispositivo.",
          Solution: "Agrega un tipo de dispositivo disponible.",
          Type: 1,
        },
      },
    };
  }

  if (
    !(
      (typeDevice >= 0 && typeDevice <= 10) ||
      typeDevice == 100 ||
      typeDevice == -100
    )
  ) {
    return {
      result: false,
      response: {
        Error: {
          Code: "WSC00",
          Message: "Ese tipo de dispositivo no esta permitido.",
          Solution: "Verifica la lista de dispositivos disponibles.",
          Type: 1,
        },
      },
    };
  }

  if (!admixProduct) {
    return {
      result: false,
      response: {
        Error: {
          Code: "WSC00",
          Message: "No se ha especificado el tipo de aplicacion.",
          Solution: "Agrega un tipo de producto de Admix disponible.",
          Type: 1,
        },
      },
    };
  }

  if (
    !(
      (admixProduct >= -1 && admixProduct <= 16) ||
      admixProduct == 100 ||
      admixProduct == -100
    )
  ) {
    return {
      result: false,
      response: {
        Error: {
          Code: "WSC00",
          Message: "Ese tipo de aplicacion no esta permitida.",
          Solution: "Verifica la lista de productos de Admix disponibles.",
          Type: 1,
        },
      },
    };
  }
  //#endregion

  // Formar el Request con los datos de la peticion
  let request = {
    Request: {
      DeviceKey: devicekey,
      AdmixProduct: admixProduct,
      TypeDevice: typeDevice,
      IdEmpresa: idEmpresa,
      IdUser: idUser,
      NumItems: numItems,
      NumItemsLoaded: numItemsLoaded,
      Search: search,
    },
  };

  try {
    //Enviar Peticion
    var res = await axios.post(url, request);

    //Regresar Respuesta obtenida de la variable data
    return {
      result: res.data.SAT_Proveedores_Widget_ProveedoresEncontradosResult,
      response: res.data.Response,
    };
  } catch (error) {
    wsResponse.response = {
      Error: {
        Code: "WSC01",
        Message: error,
        Solution: "Verifique la conección a la API de Admix.",
        Type: 1,
      },
    };
    return wsResponse;
  }
}

async function SAT_Proveedores_Widget_ProveedoresEncontrados_PersonasMorales(
  devicekey,
  typeDevice,
  admixProduct,
  idEmpresa,
  idUser,
  numItems,
  numItemsLoaded,
  search
) {
  const url = `${wsBaseUrl}SAT_Proveedores_Widget_ProveedoresEncontrados_PersonasMorales/`;

  //#region Validaciones
  if (!devicekey) {
    return {
      result: false,
      response: {
        Error: {
          Code: "WSC00",
          Message: "No se ha especificado una llave de dispositivo.",
          Solution: "Verifique la conección a la API de Admix.",
          Type: 1,
        },
      },
    };
  }

  if (!typeDevice) {
    return {
      result: false,
      response: {
        Error: {
          Code: "WSC00",
          Message: "No se ha especificado el tipo de dispositivo.",
          Solution: "Agrega un tipo de dispositivo disponible.",
          Type: 1,
        },
      },
    };
  }

  if (
    !(
      (typeDevice >= 0 && typeDevice <= 10) ||
      typeDevice == 100 ||
      typeDevice == -100
    )
  ) {
    return {
      result: false,
      response: {
        Error: {
          Code: "WSC00",
          Message: "Ese tipo de dispositivo no esta permitido.",
          Solution: "Verifica la lista de dispositivos disponibles.",
          Type: 1,
        },
      },
    };
  }

  if (!admixProduct) {
    return {
      result: false,
      response: {
        Error: {
          Code: "WSC00",
          Message: "No se ha especificado el tipo de aplicacion.",
          Solution: "Agrega un tipo de producto de Admix disponible.",
          Type: 1,
        },
      },
    };
  }

  if (
    !(
      (admixProduct >= -1 && admixProduct <= 16) ||
      admixProduct == 100 ||
      admixProduct == -100
    )
  ) {
    return {
      result: false,
      response: {
        Error: {
          Code: "WSC00",
          Message: "Ese tipo de aplicacion no esta permitida.",
          Solution: "Verifica la lista de productos de Admix disponibles.",
          Type: 1,
        },
      },
    };
  }
  //#endregion

  // Formar el Request con los datos de la peticion
  let request = {
    Request: {
      DeviceKey: devicekey,
      AdmixProduct: admixProduct,
      TypeDevice: typeDevice,
      IdEmpresa: idEmpresa,
      IdUser: idUser,
      NumItems: numItems,
      NumItemsLoaded: numItemsLoaded,
      Search: search,
    },
  };

  try {
    //Enviar Peticion
    var res = await axios.post(url, request);

    //Regresar Respuesta obtenida de la variable data
    return {
      result:
        res.data
          .SAT_Proveedores_Widget_ProveedoresEncontrados_PersonasMoralesResult,
      response: res.data.Response,
    };
  } catch (error) {
    wsResponse.response = {
      Error: {
        Code: "WSC01",
        Message: error,
        Solution: "Verifique la conección a la API de Admix.",
        Type: 1,
      },
    };
    return wsResponse;
  }
}

async function SAT_Proveedores_Widget_ProveedoresEncontrados_PersonasFisicas(
  devicekey,
  typeDevice,
  admixProduct,
  idEmpresa,
  idUser,
  numItems,
  numItemsLoaded,
  search
) {
  const url = `${wsBaseUrl}SAT_Proveedores_Widget_ProveedoresEncontrados_PersonasFisicas/`;

  //#region Validaciones
  if (!devicekey) {
    return {
      result: false,
      response: {
        Error: {
          Code: "WSC00",
          Message: "No se ha especificado una llave de dispositivo.",
          Solution: "Verifique la conección a la API de Admix.",
          Type: 1,
        },
      },
    };
  }

  if (!typeDevice) {
    return {
      result: false,
      response: {
        Error: {
          Code: "WSC00",
          Message: "No se ha especificado el tipo de dispositivo.",
          Solution: "Agrega un tipo de dispositivo disponible.",
          Type: 1,
        },
      },
    };
  }

  if (
    !(
      (typeDevice >= 0 && typeDevice <= 10) ||
      typeDevice == 100 ||
      typeDevice == -100
    )
  ) {
    return {
      result: false,
      response: {
        Error: {
          Code: "WSC00",
          Message: "Ese tipo de dispositivo no esta permitido.",
          Solution: "Verifica la lista de dispositivos disponibles.",
          Type: 1,
        },
      },
    };
  }

  if (!admixProduct) {
    return {
      result: false,
      response: {
        Error: {
          Code: "WSC00",
          Message: "No se ha especificado el tipo de aplicacion.",
          Solution: "Agrega un tipo de producto de Admix disponible.",
          Type: 1,
        },
      },
    };
  }

  if (
    !(
      (admixProduct >= -1 && admixProduct <= 16) ||
      admixProduct == 100 ||
      admixProduct == -100
    )
  ) {
    return {
      result: false,
      response: {
        Error: {
          Code: "WSC00",
          Message: "Ese tipo de aplicacion no esta permitida.",
          Solution: "Verifica la lista de productos de Admix disponibles.",
          Type: 1,
        },
      },
    };
  }
  //#endregion

  // Formar el Request con los datos de la peticion
  let request = {
    Request: {
      DeviceKey: devicekey,
      AdmixProduct: admixProduct,
      TypeDevice: typeDevice,
      IdEmpresa: idEmpresa,
      IdUser: idUser,
      NumItems: numItems,
      NumItemsLoaded: numItemsLoaded,
      Search: search,
    },
  };

  try {
    //Enviar Peticion
    var res = await axios.post(url, request);

    //Regresar Respuesta obtenida de la variable data
    return {
      result:
        res.data
          .SAT_Proveedores_Widget_ProveedoresEncontrados_PersonasFisicasResult,
      response: res.data.Response,
    };
  } catch (error) {
    wsResponse.response = {
      Error: {
        Code: "WSC01",
        Message: error,
        Solution: "Verifique la conección a la API de Admix.",
        Type: 1,
      },
    };
    return wsResponse;
  }
}

async function SAT_Proveedores_Lista_Top5NumFacturas(
  devicekey,
  typeDevice,
  admixProduct,
  id,
  idEmpresa,
  idUser,
  numItems,
  numItemsLoaded,
  search
) {
  const url = `${wsBaseUrl}SAT_Proveedores_Lista_Top5NumFacturas/`;

  //#region Validaciones
  if (!devicekey) {
    return {
      result: false,
      response: {
        Error: {
          Code: "WSC00",
          Message: "No se ha especificado una llave de dispositivo.",
          Solution: "Verifique la conección a la API de Admix.",
          Type: 1,
        },
      },
    };
  }

  if (!typeDevice) {
    return {
      result: false,
      response: {
        Error: {
          Code: "WSC00",
          Message: "No se ha especificado el tipo de dispositivo.",
          Solution: "Agrega un tipo de dispositivo disponible.",
          Type: 1,
        },
      },
    };
  }

  if (
    !(
      (typeDevice >= 0 && typeDevice <= 10) ||
      typeDevice == 100 ||
      typeDevice == -100
    )
  ) {
    return {
      result: false,
      response: {
        Error: {
          Code: "WSC00",
          Message: "Ese tipo de dispositivo no esta permitido.",
          Solution: "Verifica la lista de dispositivos disponibles.",
          Type: 1,
        },
      },
    };
  }

  if (!admixProduct) {
    return {
      result: false,
      response: {
        Error: {
          Code: "WSC00",
          Message: "No se ha especificado el tipo de aplicacion.",
          Solution: "Agrega un tipo de producto de Admix disponible.",
          Type: 1,
        },
      },
    };
  }

  if (
    !(
      (admixProduct >= -1 && admixProduct <= 16) ||
      admixProduct == 100 ||
      admixProduct == -100
    )
  ) {
    return {
      result: false,
      response: {
        Error: {
          Code: "WSC00",
          Message: "Ese tipo de aplicacion no esta permitida.",
          Solution: "Verifica la lista de productos de Admix disponibles.",
          Type: 1,
        },
      },
    };
  }
  //#endregion

  // Formar el Request con los datos de la peticion
  let request = {
    Request: {
      DeviceKey: devicekey,
      AdmixProduct: admixProduct,
      TypeDevice: typeDevice,
      Id: id,
      IdEmpresa: idEmpresa,
      IdUser: idUser,
      NumItems: numItems,
      NumItemsLoaded: numItemsLoaded,
      Search: search,
    },
  };

  try {
    //Enviar Peticion
    var res = await axios.post(url, request);

    //Regresar Respuesta obtenida de la variable data
    return {
      result: res.data.SAT_Proveedores_Lista_Top5NumFacturasResult,
      response: res.data.Response,
    };
  } catch (error) {
    wsResponse.response = {
      Error: {
        Code: "WSC01",
        Message: error,
        Solution: "Verifique la conección a la API de Admix.",
        Type: 1,
      },
    };
    return wsResponse;
  }
}

async function SAT_Proveedores_Lista_Top5Importe(
  devicekey,
  typeDevice,
  admixProduct,
  id,
  idEmpresa,
  idUser,
  numItems,
  numItemsLoaded,
  formaPago,
  search
) {
  const url = `${wsBaseUrl}SAT_Proveedores_Lista_Top5Importe/`;

  //#region Validaciones
  if (!devicekey) {
    return {
      result: false,
      response: {
        Error: {
          Code: "WSC00",
          Message: "No se ha especificado una llave de dispositivo.",
          Solution: "Verifique la conección a la API de Admix.",
          Type: 1,
        },
      },
    };
  }

  if (!typeDevice) {
    return {
      result: false,
      response: {
        Error: {
          Code: "WSC00",
          Message: "No se ha especificado el tipo de dispositivo.",
          Solution: "Agrega un tipo de dispositivo disponible.",
          Type: 1,
        },
      },
    };
  }

  if (
    !(
      (typeDevice >= 0 && typeDevice <= 10) ||
      typeDevice == 100 ||
      typeDevice == -100
    )
  ) {
    return {
      result: false,
      response: {
        Error: {
          Code: "WSC00",
          Message: "Ese tipo de dispositivo no esta permitido.",
          Solution: "Verifica la lista de dispositivos disponibles.",
          Type: 1,
        },
      },
    };
  }

  if (!admixProduct) {
    return {
      result: false,
      response: {
        Error: {
          Code: "WSC00",
          Message: "No se ha especificado el tipo de aplicacion.",
          Solution: "Agrega un tipo de producto de Admix disponible.",
          Type: 1,
        },
      },
    };
  }

  if (
    !(
      (admixProduct >= -1 && admixProduct <= 16) ||
      admixProduct == 100 ||
      admixProduct == -100
    )
  ) {
    return {
      result: false,
      response: {
        Error: {
          Code: "WSC00",
          Message: "Ese tipo de aplicacion no esta permitida.",
          Solution: "Verifica la lista de productos de Admix disponibles.",
          Type: 1,
        },
      },
    };
  }
  //#endregion

  // Formar el Request con los datos de la peticion
  let request = {
    Request: {
      DeviceKey: devicekey,
      AdmixProduct: admixProduct,
      TypeDevice: typeDevice,
      FormaPago: formaPago,
      Id: id,
      IdEmpresa: idEmpresa,
      IdUser: idUser,
      NumItems: numItems,
      NumItemsLoaded: numItemsLoaded,
      Search: search,
    },
  };

  try {
    //Enviar Peticion
    var res = await axios.post(url, request);

    //Regresar Respuesta obtenida de la variable data
    return {
      result: res.data.SAT_Proveedores_Lista_Top5ImporteResult,
      response: res.data.Response,
    };
  } catch (error) {
    wsResponse.response = {
      Error: {
        Code: "WSC01",
        Message: error,
        Solution: "Verifique la conección a la API de Admix.",
        Type: 1,
      },
    };
    return wsResponse;
  }
}

async function SAT_Proveedores_Geolocalizacion(
  devicekey,
  typeDevice,
  admixProduct,
  idEmpresa,
  idUser
) {
  const url = `${wsBaseUrl}SAT_Proveedores_Geolocalizacion/`;

  //#region Validaciones
  if (!devicekey) {
    return {
      result: false,
      response: {
        Error: {
          Code: "WSC00",
          Message: "No se ha especificado una llave de dispositivo.",
          Solution: "Verifique la conección a la API de Admix.",
          Type: 1,
        },
      },
    };
  }

  if (!typeDevice) {
    return {
      result: false,
      response: {
        Error: {
          Code: "WSC00",
          Message: "No se ha especificado el tipo de dispositivo.",
          Solution: "Agrega un tipo de dispositivo disponible.",
          Type: 1,
        },
      },
    };
  }

  if (
    !(
      (typeDevice >= 0 && typeDevice <= 10) ||
      typeDevice == 100 ||
      typeDevice == -100
    )
  ) {
    return {
      result: false,
      response: {
        Error: {
          Code: "WSC00",
          Message: "Ese tipo de dispositivo no esta permitido.",
          Solution: "Verifica la lista de dispositivos disponibles.",
          Type: 1,
        },
      },
    };
  }

  if (!admixProduct) {
    return {
      result: false,
      response: {
        Error: {
          Code: "WSC00",
          Message: "No se ha especificado el tipo de aplicacion.",
          Solution: "Agrega un tipo de producto de Admix disponible.",
          Type: 1,
        },
      },
    };
  }

  if (
    !(
      (admixProduct >= -1 && admixProduct <= 16) ||
      admixProduct == 100 ||
      admixProduct == -100
    )
  ) {
    return {
      result: false,
      response: {
        Error: {
          Code: "WSC00",
          Message: "Ese tipo de aplicacion no esta permitida.",
          Solution: "Verifica la lista de productos de Admix disponibles.",
          Type: 1,
        },
      },
    };
  }
  //#endregion

  // Formar el Request con los datos de la peticion
  let request = {
    Request: {
      DeviceKey: devicekey,
      AdmixProduct: admixProduct,
      TypeDevice: typeDevice,
      IdEmpresa: idEmpresa,
      IdUser: idUser,
    },
  };

  try {
    //Enviar Peticion
    var res = await axios.post(url, request);

    //Regresar Respuesta obtenida de la variable data
    return {
      result: res.data.SAT_Proveedores_GeolocalizacionResult,
      response: res.data.Response,
    };
  } catch (error) {
    wsResponse.response = {
      Error: {
        Code: "WSC01",
        Message: error,
        Solution: "Verifique la conección a la API de Admix.",
        Type: 1,
      },
    };
    return wsResponse;
  }
}

async function SAT_Proveedores_Widget_FormaPagoPorcentajes(
  devicekey,
  typeDevice,
  admixProduct,
  idEmpresa,
  idUser
) {
  const url = `${wsBaseUrl}SAT_Proveedores_Widget_FormaPagoPorcentajes/`;

  //#region Validaciones
  if (!devicekey) {
    return {
      result: false,
      response: {
        Error: {
          Code: "WSC00",
          Message: "No se ha especificado una llave de dispositivo.",
          Solution: "Verifique la conección a la API de Admix.",
          Type: 1,
        },
      },
    };
  }

  if (!typeDevice) {
    return {
      result: false,
      response: {
        Error: {
          Code: "WSC00",
          Message: "No se ha especificado el tipo de dispositivo.",
          Solution: "Agrega un tipo de dispositivo disponible.",
          Type: 1,
        },
      },
    };
  }

  if (
    !(
      (typeDevice >= 0 && typeDevice <= 10) ||
      typeDevice == 100 ||
      typeDevice == -100
    )
  ) {
    return {
      result: false,
      response: {
        Error: {
          Code: "WSC00",
          Message: "Ese tipo de dispositivo no esta permitido.",
          Solution: "Verifica la lista de dispositivos disponibles.",
          Type: 1,
        },
      },
    };
  }

  if (!admixProduct) {
    return {
      result: false,
      response: {
        Error: {
          Code: "WSC00",
          Message: "No se ha especificado el tipo de aplicacion.",
          Solution: "Agrega un tipo de producto de Admix disponible.",
          Type: 1,
        },
      },
    };
  }

  if (
    !(
      (admixProduct >= -1 && admixProduct <= 16) ||
      admixProduct == 100 ||
      admixProduct == -100
    )
  ) {
    return {
      result: false,
      response: {
        Error: {
          Code: "WSC00",
          Message: "Ese tipo de aplicacion no esta permitida.",
          Solution: "Verifica la lista de productos de Admix disponibles.",
          Type: 1,
        },
      },
    };
  }
  //#endregion

  // Formar el Request con los datos de la peticion
  let request = {
    Request: {
      DeviceKey: devicekey,
      AdmixProduct: admixProduct,
      TypeDevice: typeDevice,
      IdEmpresa: idEmpresa,
      IdUser: idUser,
    },
  };

  try {
    //Enviar Peticion
    var res = await axios.post(url, request);

    //Regresar Respuesta obtenida de la variable data
    return {
      result: res.data.SAT_Proveedores_Widget_FormaPagoPorcentajesResult,
      response: res.data.Response,
    };
  } catch (error) {
    wsResponse.response = {
      Error: {
        Code: "WSC01",
        Message: error,
        Solution: "Verifique la conección a la API de Admix.",
        Type: 1,
      },
    };
    return wsResponse;
  }
}

async function SAT_Proveedores_Widget_MetodoPagoPorcentajes(
  devicekey,
  typeDevice,
  admixProduct,
  idEmpresa,
  idUser
) {
  const url = `${wsBaseUrl}SAT_Proveedores_Widget_MetodoPagoPorcentajes/`;

  //#region Validaciones
  if (!devicekey) {
    return {
      result: false,
      response: {
        Error: {
          Code: "WSC00",
          Message: "No se ha especificado una llave de dispositivo.",
          Solution: "Verifique la conección a la API de Admix.",
          Type: 1,
        },
      },
    };
  }

  if (!typeDevice) {
    return {
      result: false,
      response: {
        Error: {
          Code: "WSC00",
          Message: "No se ha especificado el tipo de dispositivo.",
          Solution: "Agrega un tipo de dispositivo disponible.",
          Type: 1,
        },
      },
    };
  }

  if (
    !(
      (typeDevice >= 0 && typeDevice <= 10) ||
      typeDevice == 100 ||
      typeDevice == -100
    )
  ) {
    return {
      result: false,
      response: {
        Error: {
          Code: "WSC00",
          Message: "Ese tipo de dispositivo no esta permitido.",
          Solution: "Verifica la lista de dispositivos disponibles.",
          Type: 1,
        },
      },
    };
  }

  if (!admixProduct) {
    return {
      result: false,
      response: {
        Error: {
          Code: "WSC00",
          Message: "No se ha especificado el tipo de aplicacion.",
          Solution: "Agrega un tipo de producto de Admix disponible.",
          Type: 1,
        },
      },
    };
  }

  if (
    !(
      (admixProduct >= -1 && admixProduct <= 16) ||
      admixProduct == 100 ||
      admixProduct == -100
    )
  ) {
    return {
      result: false,
      response: {
        Error: {
          Code: "WSC00",
          Message: "Ese tipo de aplicacion no esta permitida.",
          Solution: "Verifica la lista de productos de Admix disponibles.",
          Type: 1,
        },
      },
    };
  }
  //#endregion

  // Formar el Request con los datos de la peticion
  let request = {
    Request: {
      DeviceKey: devicekey,
      AdmixProduct: admixProduct,
      TypeDevice: typeDevice,
      IdEmpresa: idEmpresa,
      IdUser: idUser,
    },
  };

  try {
    //Enviar Peticion
    var res = await axios.post(url, request);

    //Regresar Respuesta obtenida de la variable data
    return {
      result: res.data.SAT_Proveedores_Widget_MetodoPagoPorcentajesResult,
      response: res.data.Response,
    };
  } catch (error) {
    wsResponse.response = {
      Error: {
        Code: "WSC01",
        Message: error,
        Solution: "Verifique la conección a la API de Admix.",
        Type: 1,
      },
    };
    return wsResponse;
  }
}

export default {
  SAT_Proveedores_Lista,
  SAT_Proveedores_Widget_ProveedoresEncontrados,
  SAT_Proveedores_Widget_ProveedoresEncontrados_PersonasMorales,
  SAT_Proveedores_Widget_ProveedoresEncontrados_PersonasFisicas,
  SAT_Proveedores_Lista_Top5NumFacturas,
  SAT_Proveedores_Lista_Top5Importe,
  SAT_Proveedores_Geolocalizacion,
  SAT_Proveedores_Widget_FormaPagoPorcentajes,
  SAT_Proveedores_Widget_MetodoPagoPorcentajes,
};
