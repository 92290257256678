import { createRouter, createWebHistory } from "vue-router";
import SignIn from "../components/Onboarding/SignIn";
import wsCompany from "@/services/modules/wsCompany";
// import enums from "@/services/enums/enums";
import { useRouter } from "vue-router";
import { useUserStore } from "@/components/stores/UserStore";
import { useCompanyStore } from "@/components/stores/CompanyStore";
import DefaultLayout from "@/components/Layout/Templates/DefaultLayout.vue";
import MainLayout from "@/components/Layout/Templates/MainLayout.vue";
const routes = [
  {
    path: "/",
    name: "signin",
    component: SignIn,
    meta: {
      title: "ZA | Iniciar sesión",
      layout: DefaultLayout,
    },
  },
  {
    path: "/signup",
    name: "signup",
    component: () => import("../components/Onboarding/SignUp"),
    meta: {
      title: "ZA | Crear cuenta",
      layout: DefaultLayout,
    },
  },
  {
    path: "/signupsuccess",
    name: "signupsuccess",
    component: () => import("../components/Onboarding/SignUpSucces"),
    meta: {
      title: "",
      layout: DefaultLayout,
    },
  },
  {
    path: "/password",
    name: "password",
    component: () => import("../components/Onboarding/ChangePassword"),
    beforeEnter(to, from) {
      if (from.name === "otp") {
        const hasCode =
          localStorage.getItem("code") != null
            ? localStorage.getItem("code").length === 6
            : false;
        return hasCode;
      }
      return false;
    },
    meta: {
      title: "",
      layout: DefaultLayout,
    },
  },
  {
    path: "/passwordsuccess",
    name: "passwordsuccess",
    component: () => import("../components/Onboarding/ChangeSuccess"),
    meta: {
      title: "",
      layout: DefaultLayout,
    },
  },
  {
    path: "/forgot",
    name: "forgot",
    component: () => import("../components/Onboarding/ForgotPassword"),
    meta: {
      title: "ZA | Restablecer contraseña",
      layout: DefaultLayout,
    },
  },
  {
    path: "/otp",
    name: "otp",
    component: () => import("../components/Onboarding/OtpVerification"),
    beforeEnter(to, from) {
      const userStore = useUserStore();
      const router = useRouter();
      if (from.name === "forgot" || userStore.isRecoveryMode) {
        if (userStore.isRecoveryMode) {
          return userStore.isRecoveryMode;
        }
        router.push("forgot");
      } else if (from.name === "signup") {
        return userStore.user.email != null;
      }
      // router.push("forgot");
      return false;
    },
    meta: {
      title: "",
      layout: DefaultLayout,
    },
  },
  {
    path: "/terms",
    name: "terms",
    component: () => import("../components/Xml/Views/Legal/TermsView"),
    meta: {
      title: "ZA | Terminos de uso",
      layout: DefaultLayout,
    },
  },
  {
    path: "/privacy",
    name: "privacy",
    component: () => import("../components/Xml/Views/Legal/PrivacyView"),
    meta: {
      title: "ZA | Privacidad",
      layout: DefaultLayout,
    },
  },
  {
    path: "/home",
    name: "home",
    meta: {
      title: "ZA | Dashboard",
      layout: MainLayout,
    },
    component: () => import("@/components/Xml/Home/HomeView.vue"),
    beforeEnter(to, from) {
      const userStore = useUserStore();
      const companyStore = useCompanyStore();
      // const hasDevKey = localStorage.getItem("devkey") != null;
      if (userStore.isAuthenticated && companyStore.hasCompanies) {
        // const devkey = localStorage.getItem("devkey");
        // const userId = localStorage.getItem("userId") ?? 0;
        // const hasCompany = wsCompany
        //   .HasCompany(
        //     devkey,
        //     enums.TypeDevice.Web,
        //     enums.AdmixProduct.AdmixManagement,
        //     userId
        //   )
        //   .then((res) => {
        //     if (res.result) {
        //       // console.log(res);
        //       if (res.response.ListCompanies) {
        //         localStorage.setItem(
        //           "companies",
        //           JSON.stringify(res.response.ListCompanies)
        //         );
        //         return true;
        //       }
        //     }
        //     return false;
        //   });
        return true;
      }
      return false;
    },
  },
  {
    path: "/emision",
    name: "emision",
    meta: {
      title: "ZA | Emisión",
    },
    children: [
      {
        path: "dashboard",
        component: () => import("../components/ProfileView"),
        beforeEnter(to, from) {
          const userStore = useUserStore();
          if (userStore.isAuthenticated) {
            return true;
          }
          return false;
        },
        meta: {
          layout: MainLayout,
        },
      },
      {
        path: "cfdi",
        component: () => import("@/components/Xml/Emision/EmisionVue"),
        beforeEnter(to, from) {
          const userStore = useUserStore();
          if (userStore.isAuthenticated) {
            return true;
          }
          return false;
        },
        meta: {
          layout: MainLayout,
        },
      },
      {
        path: "pagos",
        component: () =>
          import("@/components/Xml/Emision/Payments/PaymentsVue.vue"),
        beforeEnter(to, from) {
          const userStore = useUserStore();
          if (userStore.isAuthenticated) {
            return true;
          }
          return false;
        },
        meta: {
          layout: MainLayout,
        },
      },
    ],
  },
  {
    path: "/recepcion",
    name: "recepcion",
    meta: {
      title: "ZA | Recepción",
    },
    children: [
      {
        path: "dashboard",
        component: () => import("../components/ProfileView"),
        beforeEnter(to, from) {
          const userStore = useUserStore();
          if (userStore.isAuthenticated) {
            return true;
          }
          return false;
        },
        meta: {
          layout: MainLayout,
        },
      },
      {
        path: "cfdi",
        component: () => import("@/components/Xml/Receipt/ReceiptVue.vue"),
        beforeEnter(to, from) {
          const userStore = useUserStore();
          if (userStore.isAuthenticated) {
            return true;
          }
          return false;
        },
        meta: {
          layout: MainLayout,
        },
      },
      {
        path: "pagos",
        component: () =>
          import("@/components/Xml/Receipt/Payments/PaymentsVue.vue"),
        beforeEnter(to, from) {
          const userStore = useUserStore();
          if (userStore.isAuthenticated) {
            return true;
          }
          return false;
        },
        meta: {
          layout: MainLayout,
        },
      },
    ],
  },
  {
    path: "/clientes",
    name: "clientes",
    component: () => import("@/components/Xml/Customers/CustomersVue"),
    beforeEnter(to, from) {
      const userStore = useUserStore();
      if (userStore.isAuthenticated) {
        return true;
      }
      return false;
    },
    meta: {
      title: "ZA | Clientes",
      layout: MainLayout,
    },
  },
  {
    path: "/proveedores",
    name: "proveedores",
    component: () => import("@/components/Xml/Providers/ProvidersVue"),
    beforeEnter(to, from) {
      const userStore = useUserStore();
      if (userStore.isAuthenticated) {
        return true;
      }
      return false;
    },
    meta: {
      title: "ZA | Proveedores",
      layout: MainLayout,
    },
  },
  {
    path: "/productos",
    name: "productos",
    component: () => import("@/components/Xml/Products/ProductsVue"),
    beforeEnter(to, from) {
      const userStore = useUserStore();
      if (userStore.isAuthenticated) {
        return true;
      }
      return false;
    },
    meta: {
      title: "ZA | Productos",
      layout: MainLayout,
    },
  },
  {
    path: "/nomina",
    name: "nomina",
    meta: {
      title: "ZA | Nómina",
    },
    children: [
      {
        path: "cfdi",
        component: () => import("@/components/Xml/Payroll/PayrollVue"),
        beforeEnter(to, from) {
          const userStore = useUserStore();
          if (userStore.isAuthenticated) {
            return true;
          }
          return false;
        },
        meta: {
          layout: MainLayout,
        },
      },
      {
        path: "empleados",
        component: () => import("@/components/Xml/Employees/EmployeeVue.vue"),
        beforeEnter(to, from) {
          const userStore = useUserStore();
          if (userStore.isAuthenticated) {
            return true;
          }
          return false;
        },
        meta: {
          layout: MainLayout,
        },
      },
    ],
  },
  {
    path: "/fiscal",
    name: "fiscal",
    component: () => import("@/components/Xml/Fiscal/FiscalVue"),
    beforeEnter(to, from) {
      const userStore = useUserStore();
      if (userStore.isAuthenticated) {
        return true;
      }
      return false;
    },
    meta: {
      title: "ZA | Fiscal",
      layout: MainLayout,
    },
  },
  {
    path: "/onboarding",
    name: "onboarding",
    component: () => import("../components/Onboarding/OnBoarding"),
    beforeEnter(to, from) {
      const devkey = localStorage.getItem("devkey");
      if (devkey) {
        return wsCompany.HasCompany(devkey, 9, -1).then((res) => {
          // console.log(res);
          if (res.result) {
            if (res.response.ListCompanies) {
              if (res.response.ListCompanies.length == 0) {
                return true;
              }
            }
          }
          if (res.response.Error.Code === "GGE01") {
            return true;
          }
          return false;
        });
      }
      return false;
    },
    meta: {
      title: "ZA | Agregar empresa",
      layout: DefaultLayout,
    },
  },
  {
    path: "/settings",
    name: "settings",
    component: () => import("../components/Settings/SettingsView"),
    beforeEnter(to, from) {
      const userStore = useUserStore();
      if (userStore.isAuthenticated) {
        return true;
      }
      return false;
    },
    meta: {
      title: "ZA | Configuraciones",
      layout: DefaultLayout,
    },
  },
  {
    path: "/profile",
    name: "profile",
    component: () => import("../components/ProfileView"),
    beforeEnter(to, from) {
      const userStore = useUserStore();
      if (userStore.isAuthenticated) {
        return true;
      }
      return false;
    },
    meta: {
      title: "ZA | Perfil",
      layout: DefaultLayout,
    },
  },
  {
    path: "/empresa",
    name: "empresa",
    component: () => import("../components/Xml/Company/CompanyVue.vue"),
    beforeEnter(to, from) {
      const userStore = useUserStore();
      if (userStore.isAuthenticated) {
        return true;
      }
      return false;
    },
    meta: {
      title: "ZA | Agregar empresa",
      layout: DefaultLayout,
    },
  },
  {
    path: "/playground",
    name: "playground",
    component: () => import("../components/PlaygroundView"),
    meta: {
      title: "ZA | Playground",
      layout: DefaultLayout,
    },
  },
  {
    path: "/:catchAll(.*)",
    name: "error",
    component: () => import("../components/Error/NotFound"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  // console.log(to);
  document.title = to.meta.title;
  next();
});

export default router;
