<template>
  <header
    class="bg-gray-50 dark:bg-gray-900 shadow w-full z-20 fixed dark:border-b dark:border-gray-700"
  >
    <div class="mx-auto px-4 py-3 sm:px-6">
      <div class="flex items-center">
        <!-- Menu button -->
        <button
          data-drawer-target="drawer-navigation"
          data-drawer-toggle="drawer-navigation"
          aria-controls="drawer-navigation"
          class="p-2 mr-2 text-gray-600 rounded-lg cursor-pointer md:hidden hover:text-gray-900 hover:bg-gray-100 focus:bg-gray-100 dark:focus:bg-gray-700 focus:ring-2 focus:ring-gray-100 dark:focus:ring-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
          @click="onClickMenuButton"
        >
          <svg
            aria-hidden="true"
            class="w-6 h-6"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <line x1="3" y1="12" x2="21" y2="12"></line>
            <line x1="3" y1="6" x2="21" y2="6"></line>
            <line x1="3" y1="18" x2="21" y2="18"></line>
          </svg>
        </button>
        <!-- END Button menu -->
        <!-- Logo -->
        <div class="flex">
          <div class="flex-shrink-0">
            <img class="h-8 w-8" src="@/assets/za_logo_child.png" alt="Admix" />
          </div>
        </div>
        <!-- END Logo -->
        <!-- Companies dropdown -->
        <div class="flex md:pl-52 pl-3">
          <div class="flex-shrink-0 block rounded-lg">
            <div class="ml-3" v-click-outside="onClickOutSideCompaniesDropdown">
              <button
                @click="onClickShowAllCompanies"
                type="button"
                class="max-w-xl px-4 py-1 tracking-wide text-gray-700 dark:text-gray-200 transition-colors duration-300 transform rounded-lg hover:bg-gray-200 dark:hover:bg-gray-700 focus:outline-none focus:ring-1 focus:ring-white focus:ring-offset-1 focus:ring-offset-gray-800"
                id="company-menu-button"
                aria-expanded="false"
                aria-haspopup="true"
              >
                {{ companyStore.companySeleted.RFC }}
                <span class="hidden md:inline-block">
                  | {{ companyStore.companySeleted.Nombre }}
                </span>
              </button>
            </div>
          </div>
        </div>
        <!-- END Companies dropdown -->
        <div class="flex-grow"></div>
        <!-- Period dropdown -->
        <div class="flex">
          <div class="flex-shrink-0 block rounded-lg">
            <div class="relative">
              <VueTailWindDatepicker
                i18n="es-mx"
                :shortcuts="customShortcuts"
                :options="options"
                v-model="dateValue"
                :formatter="formatter"
                :start-from="dateValue[0]"
                class="hidden lg:inline-block px-4 py-1 tracking-wide text-gray-700 dark:text-gray-300 transition-colors duration-300 transform rounded-lg"
              />
              <!--  -->
              <!-- -->
              <button
                @click="openDatePicker = !openDatePicker"
                type="button"
                role="menu"
                aria-orientation="vertical"
                aria-labelledby="periodpicker-menu-button"
                tabindex="-1"
                class="lg:hidden text-gray-500 transition-colors duration-300 transform dark:text-gray-400 focus:outline-none focus:ring-1 focus:ring-white focus:ring-offset-1 focus:ring-offset-gray-800 hover:text-gray-900 dark:hover:text-gray-300 hover:bg-gray-200 dark:hover:bg-gray-600 rounded-full cursor-pointer p-1"
                aria-label="show notifications"
              >
                <svg
                  class="w-6 h-6"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect>
                  <line x1="16" y1="2" x2="16" y2="6"></line>
                  <line x1="8" y1="2" x2="8" y2="6"></line>
                  <line x1="3" y1="10" x2="21" y2="10"></line>
                </svg>
              </button>
              <!-- fixed inset-0 z-50 overflow-y-auto sm:overflow-visible sm:static sm:z-auto bg-white dark:bg-vtd-secondary-800 sm:rounded-lg shadow-sm 
               fixed inset-0 z-50 overflow-y-auto bg-white sm:rounded-lg shadow-sm-->
              <div
                class="transition-all ease-out duration-300 mt-[1px] text-sm lg:text-xs 2xl:text-sm mb-2.5 translate-y-4 right-0 z-10 fixed"
                v-show="openDatePicker"
                role="menu"
                aria-orientation="vertical"
                aria-labelledby="periodpicker-menu-button"
                tabindex="-1"
              >
                <div
                  class="absolute z-20 h-4 w-4 rotate-45 mt-0.5 mr-[1.2rem] border-l border-t border-gray-300 bg-white dark:bg-slate-800 dark:border-slate-600 right-0"
                ></div>
                <div
                  class="vtd-datepicker static sm:relative w-full bg-white sm:rounded-lg sm:shadow-sm border-0 sm:border border-black/[.1] px-3 py-3 sm:px-4 sm:py-4 dark:bg-vtd-secondary-800 dark:border-vtd-secondary-700/[1] place-left"
                >
                  <VueTailWindDatepicker
                    i18n="es-mx"
                    :shortcuts="customShortcuts"
                    v-model="dateValue"
                    :formatter="formatter"
                    :start-from="dateValue[0]"
                    no-input
                  />
                  <div class="sm:hidden">
                    <div
                      class="mt-2 mx-2 py-1.5 border-t border-black/[.1] dark:border-vtd-secondary-700/[1]"
                    >
                      <div class="mt-1.5 sm:flex sm:flex-row-reverse">
                        <button
                          type="button"
                          @click="onCloseDatePicker"
                          class="away-cancel-picker w-full transition ease-out duration-300 inline-flex justify-center rounded-md border border-vtd-secondary-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-vtd-secondary-700 hover:bg-vtd-secondary-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-vtd-primary-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm dark:ring-offset-vtd-secondary-800"
                        >
                          Cerrar
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="fixed inset-0 z-50 overflow-y-auto sm:overflow-visible sm:static sm:z-auto bg-white dark:bg-vtd-secondary-800 sm:rounded-lg shadow-sm"
                v-show="false"
              >
                <div
                  class="vtd-datepicker static sm:relative w-full bg-white sm:rounded-lg sm:shadow-sm border-0 sm:border border-black/[.1] px-3 py-3 sm:px-4 sm:py-4 dark:bg-vtd-secondary-800 dark:border-vtd-secondary-700/[1] place-left"
                >
                  <VueTailWindDatepicker
                    i18n="es-mx"
                    :shortcuts="customShortcuts"
                    v-model="dateValue"
                    :formatter="formatter"
                    :start-from="dateValue[0]"
                    no-input
                  />
                  <div class="sm:hidden">
                    <div
                      class="mt-2 mx-2 py-1.5 border-t border-black/[.1] dark:border-vtd-secondary-700/[1]"
                    >
                      <div class="mt-1.5 sm:flex sm:flex-row-reverse">
                        <button
                          type="button"
                          @click="onCloseDatePicker"
                          class="away-cancel-picker w-full transition ease-out duration-300 inline-flex justify-center rounded-md border border-vtd-secondary-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-vtd-secondary-700 hover:bg-vtd-secondary-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-vtd-primary-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm dark:ring-offset-vtd-secondary-800"
                        >
                          Cerrar
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- END Period dropdown -->
        <!-- Notifications and profile dropdowns -->
        <div>
          <div class="ml-4 flex items-center md:ml-6">
            <!-- Notification dropdown -->
            <div
              class="relative ml-2"
              v-click-outside="onClickOutSideNotificationsDropdown"
            >
              <button
                @click="openNotifications = !openNotifications"
                type="button"
                role="menu"
                aria-orientation="vertical"
                aria-labelledby="menu-button"
                tabindex="-1"
                class="mx-4 text-gray-500 transition-colors duration-300 transform lg:block dark:text-gray-400 focus:outline-none focus:ring-1 focus:ring-white focus:ring-offset-1 focus:ring-offset-gray-800 hover:text-gray-900 dark:hover:text-gray-300 hover:bg-gray-200 dark:hover:bg-gray-600 rounded-full cursor-pointer p-1"
                aria-label="show notifications"
              >
                <svg
                  class="w-6 h-6"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path
                    d="M22 17H2a3 3 0 0 0 3-3V9a7 7 0 0 1 14 0v5a3 3 0 0 0 3 3zm-8.27 4a2 2 0 0 1-3.46 0"
                  ></path>
                </svg>
                <!-- Notification status badge -->
                <div
                  class="animate-ping w-2.5 h-2.5 bg-red-700 rounded-full absolute -top-0.5 -right-0.5 m-auto duration-200"
                ></div>
                <div
                  class="w-1.5 h-1.5 bg-red-700 rounded-full absolute top-0 right-0 m-auto shadow-lg"
                ></div>
              </button>

              <notifications-dropdown v-show="openNotifications" />
            </div>
            <!-- END Notification dropdown -->
            <!-- Profile dropdown -->
            <div
              class="relative ml-2"
              v-click-outside="onClickOutSideUserProfileDropdown"
            >
              <button
                @click="openMenu = !openMenu"
                type="button"
                class="relative flex max-w-xs items-center rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-1 focus:ring-white focus:ring-offset-1 focus:ring-offset-gray-800"
                id="user-menu-button"
                aria-expanded="false"
                aria-haspopup="true"
              >
                <span class="absolute -inset-1.5"></span>
                <span class="sr-only">Open user menu</span>
                <!-- IF !(AVATAR) -->
                <div
                  v-if="!photoUser"
                  class="relative inline-flex items-center justify-center w-8 h-8 overflow-hidden bg-gray-300 rounded-full dark:bg-gray-600"
                >
                  <span class="font-medium text-gray-600 dark:text-gray-300">
                    {{ initials }}
                  </span>
                </div>
                <!-- ELSE -->
                <img
                  v-if="photoUser"
                  class="h-8 w-8 rounded-full"
                  :src="photoUser"
                  alt=""
                />
              </button>
              <!-- USER MENU -->
              <user-menu-dropdown
                :email="email"
                :userName="userName"
                :photo="photoUser"
                :show="openMenu"
              />
            </div>
            <!-- END Profile dropdown -->
          </div>
        </div>
        <!-- END Notifications and profile dropdowns -->
      </div>
    </div>
  </header>
</template>
<script setup>
import { computed, onMounted, ref, defineEmits, watch } from "vue";
import NotificationsDropdown from "@/components/Layout/NotificationsDropdown.vue";
import UserMenuDropdown from "./UserMenuDropdown.vue";
// import CompaniesDropdown from "@/components/Xml/Home/Controls/CompaniesDropdown.vue";
import commons from "@/components/Commons/commons";
// import wsKernel from "@/services/modules/wsKernel";
import VueTailWindDatepicker from "vue-tailwind-datepicker";
import { useCompanyStore } from "@/components/stores/CompanyStore";
import { useUserStore } from "../stores/UserStore";

const userStore = useUserStore();
const companyStore = useCompanyStore();
const openMenu = ref(false);
const openDatePicker = ref(false);
const openNotifications = ref(false);
const open = ref(false);
const email = ref("");
const userName = ref("");
const photoUser = ref("");

const dateValue = ref({
  startDate: "",
  endDate: "",
});

const customShortcuts = () => {
  return [
    {
      label: "Hoy",
      atClick: () => {
        const date = new Date();
        openDatePicker.value = false;
        return [date, date];
      },
    },
    {
      label: "Ayer",
      atClick: () => {
        const date = new Date();
        const yesterday = date.setDate(date.getDate() - 1);
        return [yesterday, yesterday];
      },
    },
    {
      label: "Mes actual",
      atClick: () => {
        const date = new Date();
        return [
          new Date(date.getFullYear(), date.getMonth(), 1),
          new Date(date.getFullYear(), date.getMonth() + 1, 0),
        ];
      },
    },
    {
      label: "Mes anterior",
      atClick: () => {
        const date = new Date();
        const month = date.getMonth();
        return [
          new Date(date.getFullYear(), month - 1, 1),
          new Date(date.getFullYear(), month, 0),
        ];
      },
    },
  ];
};

const options = ref({
  footer: {
    apply: "Aplicar",
    cancel: "Cerrar",
  },
});

const formatter = ref({
  date: "DD/MM/YYYY",
  month: "MMM",
});

// EMITS

const emit = defineEmits([
  "onClickShowAllCompaniesEvent",
  "onCompanySelectionChangeEvent",
  "onClickMenuButtonEvent",
  "onPeriodSelectionChangeEvent", //
]);

onMounted(() => {
  // EMPRESAS
  if (companyStore.hasCompanies) {
    const company = companyStore.companySeleted;
    onCompanySelectionChanged(company);
  }

  // USUARIO

  if (userStore.isAuthenticated) {
    email.value = userStore.user.email;
    userName.value = userStore.user.username;
    if (userStore.user.photo) {
      photoUser.value = userStore.user.photo;
    }
  }

  // PERIODO
  const params = commons.getFirstAndLastDayInAMonthFormattedEsMx(new Date());
  // console.log("params mounted", params);
  const jsonPeriod = JSON.parse(localStorage.getItem("period"));
  // console.log("jsonPediod mounted", jsonPeriod);
  const period = jsonPeriod ?? {
    startDate: params.firstDay,
    endDate: params.lastDay,
  };
  dateValue.value = period;
});

// WATCHERS

watch(dateValue, (newDate, oldDate) => {
  // console.log(newDate);
  let startDateFormated = "";
  let endDateFormated = "";
  if (newDate.startDate.includes("/")) {
    const arrayStartDate = newDate.startDate.split("/");
    startDateFormated = `${arrayStartDate[2]}-${arrayStartDate[1]}-${arrayStartDate[0]}`;
    const arrayEndDate = newDate.endDate.split("/");
    endDateFormated = `${arrayEndDate[2]}-${arrayEndDate[1]}-${arrayEndDate[0]}`;
  }

  const periodSelected = {
    startDate: newDate.startDate,
    endDate: newDate.endDate,
  };
  const periodSelectedInServerFormat = {
    startDate: `${startDateFormated}T00:00:00`,
    endDate: `${endDateFormated}T23:59:59`,
  };
  userStore.serverPeriodFormat = periodSelectedInServerFormat;
  openDatePicker.value = false;
  // console.log("open");
  localStorage.setItem("period", JSON.stringify(periodSelected));
  emit("onPeriodSelectionChangeEvent", periodSelectedInServerFormat);
});

// METHODS

// Event handlers - Click Outside Event

const onClickOutSideCompaniesDropdown = () => (open.value = false);

const onClickOutSideNotificationsDropdown = () =>
  (openNotifications.value = false);

const onClickOutSideUserProfileDropdown = () => (openMenu.value = false);

const onClickShowAllCompanies = (item) => {
  emit("onClickShowAllCompaniesEvent");
};

const onCompanySelectionChanged = (company) => {
  open.value = false;
  emit("onCompanySelectionChangeEvent", company);
};

const onClickMenuButton = () => emit("onClickMenuButtonEvent");

const onCloseDatePicker = () => (openDatePicker.value = false);

// COMPUTED

const initials = computed(() =>
  commons.getInitialLetters(userName.value.trim(), true)
);
</script>
