import { defineStore } from "pinia";
import services from "@/services";
import commons from "../Commons/commons";
import enums from "@/services/enums/enums";
import { Key } from "./KeyString";

export const useUserStore = defineStore("user", {
  state: () => ({
    user: JSON.parse(localStorage.getItem(Key.USERSESSION)) ?? null,
    devKey: localStorage.getItem(Key.DEVKEY),
    isRecoveryMode: localStorage.getItem(Key.RECOVERYMODE) === "1" ?? false,
    error: {
      Code: "",
      Message: "",
      Solution: "",
    },
    loading: false,
    serverPeriodFormat: {
      startDate: "",
      endDate: "",
    },
  }),
  getters: {
    isAuthenticated: (state) =>
      !!state.user || (state.user?.email && state.user?.userId),
    getCurrentPeriod: (state) => {
      const params = commons.getFirstAndLastDayInAMonth(new Date());
      const jsonPeriod = JSON.parse(localStorage.getItem("period"));

      let period = {
        startDate: params.firstDay,
        endDate: params.lastDay,
      };

      if (jsonPeriod) {
        if (jsonPeriod.startDate.includes("/")) {
          const arrayStartDate = jsonPeriod.startDate.split("/");
          period.startDate = `${arrayStartDate[2]}-${arrayStartDate[1]}-${arrayStartDate[0]} 00:00:00`;
          const arrayEndDate = jsonPeriod.endDate.split("/");
          period.endDate = `${arrayEndDate[2]}-${arrayEndDate[1]}-${arrayEndDate[0]} 23:59:59`;
        }
      }
      return period;
    },
  },
  actions: {
    async login(username, password) {
      this.loading = true;
      this.error = null;
      const params = username.split("/");
      const email = params.length == 1 ? params[0].trim() : params[1].trim();
      const subdomain = params.length > 1 ? params[0].trim() : "";
      const response = await services.wsLogOn.Login_Subdomain(
        email,
        password.trim(),
        subdomain,
        enums.TypeDevice.Web, // web
        enums.AdmixProduct.AdmixManagement
      );
      // console.log(response);
      if (response.result) {
        this.user = {
          email: email,
          userId: response.response.IdUser,
          username: email.split("@")[0],
        };
        // console.log(this.user);

        if (response.response.IdUser < 0) {
          const data = await services.wsKernel
            .Accounts_Get(
              response.response.DeviceKey,
              enums.TypeDevice.Web, // web
              enums.AdmixProduct.AdmixManagement
            )
            .then((res) => {
              let photo = "";
              let fullname = "";
              if (res.result) {
                console.log(res);
                if (res.response.ListAccounts) {
                  const account = res.response.ListAccounts[0];
                  fullname = account.FullName;
                  if (account.app_PhotoUser) {
                    photo = `data:image/jpg;base64,${account.app_PhotoUser}`;
                  }
                }
              }
              return {
                photo: photo,
                username: fullname,
              };
            });
          this.user.username = data.username;
          this.user.photo = data.photo;
        }
        localStorage.setItem(Key.USERSESSION, JSON.stringify(this.user));
        this.devKey = response.response.DeviceKey;
        localStorage.setItem(Key.DEVKEY, this.devKey);
        this.loading = false;
        return true;
      }
      this.error = `${response.response.Error.Code} ${response.response.Error.Message}`;
      this.loading = false;
      return false;
    },
    async getAccount_Password_Recovery_Update_App(code, password) {
      this.error = null;
      return await services.wsManagement
        .Account_Password_Recovery_Update_App(code, password)
        .then((res) => {
          if (res.result) {
            return true;
          }
          this.error.value = res.response.Error;
          return false;
        });
    },
    async getPasswordRecoveryApp(email) {
      this.error = null;
      return await services.wsManagement
        .Account_Password_Recovery_App(email)
        .then((res) => {
          if (res.result) {
            this.isRecoveryMode = true;
            this.user = {
              email: email,
              userId: null,
              username: null,
            };
            localStorage.setItem(Key.USERSESSION, JSON.stringify(this.user));
            localStorage.setItem(Key.RECOVERYMODE, "1");
            return true;
          }
          this.error = res.response.Error;
          return false;
        });
    },
    async getRegisterNewAccount(email, password, fullname) {
      this.error = null;
      return await services.wsManagement
        .RegisterNewAccount(
          email,
          password,
          45, // bundle admix management
          fullname
        )
        .then((res) => {
          console.log(res);
          if (res.result) {
            if (res.response.AccountStatus == 0) {
              this.user = {
                email: email,
                userId: null,
                username: null,
              };
              localStorage.setItem(Key.USERSESSION, JSON.stringify(this.user));
              return true;
            }
          }
          if (res.response) {
            this.error = res.response.Error;
          }
          return false;
        });
    },
    async existAccount(email) {
      this.error = null;
      return await services.wsManagement.ExistAccount(email).then((res) => {
        return res;
      });
    },
    async ReSendCode(email) {
      this.error = null;
      return await services.wsManagement.ReSendCode(email).then((res) => {
        console.log(res);
        if (res.result) {
          return true;
        }
        this.error = res.response.Error;
        return false;
      });
    },
    async getPasswordRecoveryVerifyApp(code) {
      this.error = null;
      return await services.wsManagement
        .Account_Password_Recovery_Verify_App(code)
        .then((res) => {
          if (res.result) {
            localStorage.setItem("code", code);
            return true;
          }
          this.error = res.response.Error;
          return false;
        })
        .catch((err) => console.log(err));
    },
    async VerifyRegisterNewAccount(code, email) {
      this.error = null;
      return await services.wsManagement
        .VerifyRegisterNewAccount(code)
        .then(async (res) => {
          console.log(res);
          if (res.result) {
            return await services.wsManagement
              .StartAccountProcess(email, true)
              .then((res) => {
                console.log(res);
                if (res.result) {
                  localStorage.removeItem("userEmail");
                  return true;
                }
                this.error = res.response.Error;
                return false;
              });
          }
          this.error = res.response.Error;
          return false;
        });
    },
    logout() {
      this.user = null;
      this.devKey = null;
      localStorage.removeItem(Key.USERSESSION);
      localStorage.removeItem(Key.DEVKEY);
      localStorage.removeItem(Key.COMPANIES);
      localStorage.removeItem(Key.COMPANYSELECTED);
      localStorage.removeItem(Key.PERIOD);
    },
  },
});
